import "@css/app.pcss";

import Alpine from 'alpinejs';
window.Alpine = Alpine;

import '@js/components/menu';
import '@js/components/menuMobile';

Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
  